export interface EHRInfo {
  integrationType: string;
  ehrName: string;
}

let ehrInfoState: EHRInfo = null;

export const getEHRInfoState: () => EHRInfo = () => ehrInfoState;
export const updateEHRInfoState = (newVal: EHRInfo) => {
  ehrInfoState = newVal;
};
