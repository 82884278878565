import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

const AnalyticsLogEvent_Shared_Mutation = gql`
  mutation AnalyticsLogEvent_Shared_Mutation($input: AnalyticsLogEventInput!) {
    analyticsLogEvent(input: $input) {
      errors {
        message
      }
    }
  }
`;

interface AnalyticsLogEventError {
  message: string;
}

interface AnalyticsLogEventData {
  analyticsLogEvent: {
    errors: AnalyticsLogEventError;
  };
}

interface Variables {
  input: {
    eventName: string;
    payload: any;
  };
}

const useAnalyticsLogEventMutation = (
  options?: MutationHookOptions<AnalyticsLogEventData, Variables>
) => useMutation<AnalyticsLogEventData, Variables>(AnalyticsLogEvent_Shared_Mutation, options);

export type { AnalyticsLogEventData };

/* eslint-disable @fullscript/gql-no-manual-hook-declaration */
export { useAnalyticsLogEventMutation, AnalyticsLogEvent_Shared_Mutation };
