export enum AnalyticsEvent {
  STORE_ALTERNATIVE_PRODUCT_PRODUCT_METRIC_DISPLAY = "store.alternative_product.product_metric_display",
  STORE_ALTERNATIVE_PRODUCT_LIKE_DISLIKE = "store.alternative_product.like_dislike",
  STORE_ALTERNATIVE_PRODUCT_DISLIKE_FORM_SUBMITTED = "store.alternative_product.dislike_form_submitted",
  STORE_SIDEBAR_VIEW_NOTIFICATIONS = "store.sidebar.view_notifications",
  STORE_MEGA_MENU_DROPDOWN = "store.mega_menu.dropdown",
  STORE_MEGA_MENU_CLICK_TO_NAVIGATE = "store.mega_menu.click_to_navigate",
  STORE_EXPLORE_CLICK_TO_NAVIGATE = "store.explore.click_to_navigate",
  STORE_TREATMENT_PLAN_ADD_PRODUCT = "store.treatment_plan.add_product",
  STORE_TREATMENT_PLAN_RX_UPDATE = "store.treatment_plan.rx_update",
  STORE_TREATMENT_PLAN_RX_NAVIGATE_TO_PLAN_PAGE = "store.treatment_plan.navigate_to_plan_page",
  STORE_TREATMENT_PLAN_RX_DISCARD_EDIT = "store.treatment_plan.discard_edit",
  STORE_TREATMENT_PLAN_NEW = "store.treatment_plan.new",
  STORE_TREATMENT_PLAN_EDIT_PLAN = "store.treatment_plan.edit_plan",
  STORE_TREATMENT_PLAN_VIEW_RESOURCES = "store.treatment_plan.view_resources",
  STORE_TREATMENT_PLAN_VIEW_PROTOCOL = "store.treatment_plan.view_protocol",
  STORE_TREATMENT_PLAN_ATTACH_SNIPPET = "store.treatment_plan.attach_snippet",
  STORE_TREATMENT_PLAN_ATTACH_DOCUMENT = "store.treatment_plan.attach_document",
  STORE_TREATMENT_PLAN_SELECT_PATIENT = "store.treatment_plan.select_patient",
  STORE_TREATMENT_PLAN_TREATMENT_PLAN_MESSAGE = "store.treatment_plan.treatment_plan_message",
  STORE_TREATMENT_PLAN_VIEW_PLAN = "store.treatment_plan.view_plan",
  STORE_TREATMENT_PLAN_LOAD_MESSAGE_CTA = "store.treatment_plan.load_message_cta",
  STORE_TREATMENT_PLAN_GENERATE_MESSAGE = "store.treatment_plan.generate_message",
  STORE_TREATMENT_PLAN_NAVIGATE_TO_GENERATE_MESSAGE = "store.treatment_plan.navigate_to_generate_message",
  STORE_TREATMENT_PLAN_NAVIGATE_TO_SAVED_MESSAGES = "store.treatment_plan.navigate_to_saved_messages",
  STORE_TREATMENT_PLAN_SAVE_TREATMENT_PLAN_MESSAGE = "store.treatment_plan.save_treatment_plan_message",
  STORE_TREATMENT_PLAN_PATIENT_ACCESS_DRAWER = "store.treatment_plan.patient_access_drawer",
  STORE_TREATMENT_PLAN_CHANGE_STAFF_ACCESS = "store.treatment_plan.change_staff_access",
  STORE_TREATMENT_PLAN_CHANGE_ACCESS = "store.treatment_plan.change_access",
  STORE_PROTOCOL_ADD_PRODUCT = "store.protocol.add_product",
  STORE_PROTOCOL_RX_UPDATE = "store.protocol.rx_update",
  STORE_PROTOCOL_APPLY = "store.protocol.apply",
  STORE_PROTOCOL_NEW = "store.protocol.new",
  STORE_PROTOCOL_VIEW = "store.protocol.view",
  STORE_SIGNUP_FINISH_LATER = "store.signup.finish_later",
  STORE_PROTOCOL_ACTIVATE = "store.protocol.activate",
  STORE_SIGNUP_RESUME_ACCOUNT_ONBOARDING = "store.signup.resume_account_onboarding",
  STORE_SIGNUP_TO_SIGNUP_METHOD = "store.signup.to_signup_method",
  EMERSON_SIGNUP_TO_SIGNUP_METHOD = "emerson.signup.to_signup_method",
  STORE_SIGNUP_IM_A_PATIENT = "store.signup.im_a_patient",
  STORE_PRODUCT_VIEW = "store.product.view",
  EMERSON_PRODUCT_VIEW = "emerson.product.view",
  EMERSON_UNAUTH_PRODUCT_VIEW = "emerson_unauth.product.view",
  STORE_PRODUCT_VIEW_ALTERNATIVE_PRODUCTS = "store.product.view_alternative_products",
  STORE_PRODUCT_REMOVE_ALL_COMPARISONS = "store.product.remove_all_comparisons",
  STORE_PRODUCT_SELECT_FIRST_COMPARE = "store.product.select_first_compare",
  STORE_PATIENT_NEW_PATIENT = "store.patient.new_patient",
  STORE_PATIENT_CREATE = "store.patient.create",
  STORE_PATIENT_INFORMATION_BUTTON = "store.patient.information_button",
  PATIENT_SEARCH_APPLY = "patient.search.apply",
  PATIENT_ORDER_TRACK_PACKAGE = "patient.order.click_track_package",
  PATIENT_ORDER_ORDER_NUMBER = "patient.order.click_order_number",
  PATIENT_SEARCH_APPLY_FILTER = "patient.search.apply_filter",
  STORE_SEARCH_APPLY = "store.search.apply",
  STORE_SEARCH_APPLY_FILTER = "store.search.apply_filter",
  STORE_LABS_APPLY_SEARCH = "store.labs.apply_search",
  STORE_LABS_APPLY_SEARCH_FILTER = "store.labs.apply_search_filter",
  STORE_PATIENT_IMPORT_PATIENTS = "store.patient.import_patients",
  STORE_LABS_VIEW_SENT_TEST_DETAILS = "store.labs.view_sent_test_details",
  STORE_LABS_VIEW_UPCOMING_JOURNEY_DETAILS = "store.labs.view_upcoming_journey_details",
  STORE_LABS_VIEW_RESULTS = "store.labs.view_results",
  STORE_LABS_VIEW_TEST_DESCRIPTION = "store.labs.view_test_description",
  STORE_LABS_VIEW_CUSTOM_PANEL = "store.labs.view_custom_panel",
  STORE_LABS_LEARN_MORE = "store.labs.learn_more",
  STORE_LABS_JOIN_WAITLIST = "store.labs.join_waitlist",
  STORE_LABS_VIEW_WAITLIST_BANNER = "store.labs.view_waitlist_banner",
  STORE_LABS_VIEW_JOURNEY_CTA = "store.labs.view_journey_cta",
  STORE_LABS_JOURNEY_BIOMARKER_FLAG = "store.labs_journey.biomarker_flag",
  STORE_LABS_JOURNEY_BIOMARKER_COMMENT = "store.labs_journey.biomarker_comment",
  STORE_LABS_JOURNEY_CANCEL_RECURRING_LAB = "store.labs_journey.cancel_recurring_lab",
  STORE_LABS_JOURNEY_REMOVE_LABS_JOURNEY = "store.labs_journey.remove_labs_journey",
  STORE_LABS_JOURNEY_BUILD_LABS_JOURNEY = "store.labs_journey.build_labs_journey",
  STORE_LABS_JOURNEY_TEST_TYPE = "store.labs_journey.test_type",
  STORE_LABS_JOURNEY_TEST_FREQUENCY = "store.labs_journey.test_frequency",
  STORE_LABS_JOURNEY_SHARE_WITH_PATIENT = "store.labs_journey.share_with_patient",
  STORE_LABS_JOURNEY_SHARE_WITH_PATIENT_WITHOUT_REVIEW = "store.labs_journey.share_with_patient_without_review",
  STORE_LABS_JOURNEY_SEND_REMINDER = "store.labs_journey.send_reminder",
  STORE_STAFF_NEW = "store.staff.new",
  STORE_CHECKOUT_ADD_TO_CART = "store.checkout.add_to_cart",
  EMERSON_CHECKOUT_ADD_TO_CART = "emerson.checkout.add_to_cart",
  PATIENT_ACCOUNT_SET_PASSWORD = "patient.account.set_password",
  PATIENT_AUTOSHIP_REMOVE_ITEM = "patient.autoship.cancel_autoship_item",
  PATIENT_PRODUCT_VIEW = "patient.product.view",
  PATIENT_PRODUCT_VIEW_SIMILAR_PRODUCTS = "patient.product.view_similar_products",
  PATIENT_PRODUCT_REQUEST_ACCESS = "patient.product.request_access",
  PATIENT_PRODUCT_SELECT_AUTOSHIP = "patient.product.select_autoship",
  PATIENT_PRODUCT_VIEW_PRODUCT_ACCORDION = "patient.product.view_product_accordion",
  PATIENT_CHECKOUT_ADD_TO_CART = "patient.checkout.add_to_cart",
  PATIENT_CHECKOUT_ADD_ALL_TO_CART = "patient.checkout.add_all_to_cart",
  PATIENT_VIEW_CART = "patient.checkout.view_cart",
  PATIENT_EDIT_CART = "patient.checkout.edit_cart",
  PATIENT_CHECKOUT_START = "patient.checkout.start",
  PATIENT_CHECKOUT_EDIT_ADDRESS = "patient.checkout.edit_address",
  PATIENT_CHECKOUT_CONFIRM_ADDRESS = "patient.checkout.confirm_address",
  PATIENT_CHECKOUT_ADDRESS_VALIDATION = "patient.checkout.address_validation",
  PATIENT_BUY_AGAIN_NAVIGATE_TO_PAGE = "patient.buy_again.navigate_to_page",
  PATIENT_PLAN_VIEW_FULL_PLAN = "patient.plan.view_full_plan",
  STORE_CHECKOUT_START = "store.checkout.start",
  STORE_CHECKOUT_VIEW_CART_DROPDOWN = "store.checkout.view_cart_dropdown",
  EMERSON_CHECKOUT_VIEW_CART_DROPDOWN = "emerson.checkout.view_cart_dropdown",
  STORE_CHECKOUT_TO_SHIPPING = "store.checkout.to_shipping",
  EMERSON_CHECKOUT_TO_SHIPPING = "emerson.checkout.to_shipping",
  STORE_CHECKOUT_TO_PAYMENT = "store.checkout.to_payment",
  EMERSON_CHECKOUT_START = "emerson.checkout.start",
  EMERSON_CHECKOUT_TO_PAYMENT = "emerson.checkout.to_payment",
  EMERSON_ORDERS_CANCEL_BACKORDER = "emerson.orders.cancel_backorder",
  EMERSON_SEARCH_APPLY = "emerson.search.apply",
  EMERSON_SEARCH_APPLY_FILTER = "emerson.search.apply_filter",
  EMERSON_SEARCH_CLOSE_FILTER = "emerson.search.close_filter",
  EMERSON_UNAUTH_SEARCH_APPLY = "emerson_unauth.search.apply",
  EMERSON_UNAUTH_SEARCH_APPLY_FILTER = "emerson_unauth.search.apply_filter",
  EMERSON_UNAUTH_SEARCH_CLOSE_FILTER = "emerson_unauth.search.close_filter",
  EMERSON_WHOLESALE_POLICIES_ACCEPT_BRAND_AGREEMENT = "emerson.wholesale_policies.accept_brand_agreement",
  EMERSON_WHOLESALE_POLICIES_VIEW_BRAND_AGREEMENT = "emerson.wholesale_policies.view_brand_agreement",
  EMERSON_ACCOUNT_SETTINGS_GENERATE_TAX_EXEMPT_LINK = "emerson.account_settings.generate_tax_exempt_link",
  EMERSON_ACCOUNT_SETTINGS_GO_TO_CERT_EXEMPT_PROVIDER = "emerson.account_settings.go_to_cert_exempt_provider",
  EMERSON_CART_BECOME_TAX_EXEMPT = "emerson.cart.become_tax_exempt",
  STORE_SIGNUP_ERROR = "store.signup.error",
  STORE_PAGE_VIEW = "store.page.view",
  UNAUTH_PAGE_VIEW = "unauth.page.view",
  EMERSON_PAGE_VIEW = "emerson.page.view",
  EMERSON_UNAUTH_PAGE_VIEW = "emerson_unauth.page.view",
  STORE_PILL_PACKS_VIEW = "store.pill_pack.banner_click",
  STORE_DASHBOARD_CLICK = "store.dashboard.click",
  STORE_REFERRAL_NAVIGATE_TO_REFERRALS_PAGE = "store.referral.navigate_to_referrals_page",
  STORE_REFERRAL_SHARE_REFERRAL = "store.referral.share_referral",
  STORE_REFERRAL_REFER_PRACTITIONER = "store.referral.refer_practitioner",
  STORE_LABS_START_SETUP = "store.labs.start_setup",
  STORE_LABS_ACCEPTED_TERMS_AND_CONDITIONS = "store.labs.accepted_terms_and_conditions",
  STORE_LABS_LAUNCH = "store.labs.launch",
  STORE_LABS_SEND = "store.labs.send",
  STORE_LABS_VIEW_REQUISITION = "store.labs.view_requisition",
  STORE_LABS_SEND_REMINDER = "store.labs.send_reminder",
  STORE_LABS_CREATE_CUSTOM_PANEL = "store.labs.create_custom_panel",
  STORE_LABS_START_REGISTRATION = "store.labs.start_registration",
  STORE_LABS_RX_UPDATE = "store.labs.rx_update",
  STORE_LABS_INTRODUCING_LABS_CTA = "store.labs.introducing_labs_cta",
  STORE_LABS_CLICK_LABS_MARKETING_VIDEO = "store.labs.click_labs_marketing_video",
  STORE_LABS_BOOK_A_CALL_CTA = "store.labs.book_a_call_cta",
  STORE_PAYMENTS_CHANGE_STORE_MARGIN = "store.payments.change_store_margin",
  STORE_PAYMENTS_ADD_BANK_ACCOUNT = "store.payments.add_bank_account",
  STORE_PAYMENTS_NAVIGATE_TO_PAYOUTS_PAGE = "store.payments.navigate_to_payouts_page",
  STORE_PAYMENTS_NAVIGATE_TO_PAYMENTS_PAGE = "store.payments.navigate_to_payments_page",
  STORE_SHOWCASE_ON_MY_WEBSITE = "store.product.embedded_card",
  STORE_VERIFY_TAX_ENTER_MODAL = "store.verify_tax.enter_modal",
  STORE_VERIFY_TAX_ADDRESS_DETAILS = "store.verify_tax.address_details",
  STORE_VERIFY_TAX_TAX_DETAILS = "store.verify_tax.tax_details",
  STORE_VERIFY_TAX_INITIAL_MESSAGE = "store.verify_tax.initial_message",
  STORE_VERIFY_TAX_E_DELIVERY = "store.verify_tax.e_delivery",
  STORE_WHOLESALE_POLICIES_ACCEPT_BRAND_AGREEMENT = "store.wholesale_policies.accept_brand_agreement",
  STORE_ACCOUNT_SETTINGS_GENERATE_TAX_EXEMPT_LINK = "store.account_settings.generate_tax_exempt_link",
  STORE_ACCOUNT_SETTINGS_GO_TO_CERT_EXEMPT_PROVIDER = "store.account_settings.go_to_cert_exempt_provider",
  STORE_CART_BECOME_TAX_EXEMPT = "store.cart.become_tax_exempt",
  PATIENT_PAGE_VIEW = "patient.page.view",
  PATIENT_PILL_PACKS_VIEW = "patient.pill_pack.banner_click",
  STORE_MIGRATION_LEARN_MORE = "store.migration.learn_more",
  STORE_MIGRATION_START_MIGRATION = "store.migration.start_migration",
  PATIENT_SHARED_PROTOCOL_VIEW_PROTOCOL = "patient.shared_protocol.view_protocol",
  SIGNUP_CHECK_PATIENT_INVITE = "store.signup.check_patient_invite",
  STORE_MIGRATION_FORGOT_WELLEVATE_PASSWORD = "store.migration.forgot_wellevate_password",
  STORE_MIGRATION_TO_CATALOG_PERMISSION = "store.migration.to_catalog_permission_settings",
  STORE_MIGRATION_START_UPGRADE_FS_TWO = "store.migration.start_upgrade_fs_two",
  STORE_MIGRATION_UPGRADE_TO_FS_TWO = "store.migration.upgrade_to_fs_two",
  STORE_PAGE_PLATFORM_SELECT = "store.page.platform_select",
  STORE_MANAGE_PATIENTS_SELECT_PATIENTS = "store.manage_patients.select_patients",
  STORE_MANAGE_PATIENTS_FILTER_PATIENTS = "store.manage_patients.filter_patients",
  STORE_MANAGE_PATIENTS_PRACTITIONER_ASSIGNMENT = "store.manage_patients.practitioner_assignment",
  STORE_MANAGE_PATIENTS_UNASSIGNED_PATIENTS_WARNING_BANNER = "store.manage_patients.unassigned_patients_warning_banner",
  STORE_PAYMENTS_SET_UP_PAYOUTS = "store.payments.set_up_payouts",
  STORE_PAYMENTS_BECOME_PROFIT_ACCOUNT = "store.payments.become_profit_account",
  STORE_PAYMENTS_SUBMIT_PROFIT_VERIFICATION = "store.payments.submit_profit_verification",
  STORE_PAYMENTS_ACTION_ADDITIONAL_PROFIT_INFORMATION = "store.payments.action_additional_profit_information",
  STORE_PAYMENTS_AUTOSHIP_AND_SAVE_BANNER_CLICK = "store.payments.autoship_and_save_banner_click",
  PATIENT_PAGE_PLATFORM_SELECT = "patient.page.platform_select",
  PATIENT_MIGRATION_CONNECT_TO_WELLEVATE = "patient.migration.connect_to_wellevate",
  PATIENT_MIGRATION_MIGRATION_COMPLETED = "patient.migration.migration_completed",
  STORE_FAVORITES_ADD_TO_FAVORITES = "store.favorites.add_to_favorites",
  STORE_FAVORITES_VIEW_CATEGORY = "store.favorites.view_category",
  STORE_FAVORITES_EDIT_CATEGORY = "store.favorites.edit_category",
  STORE_FAVORITES_CREATE_CATEGORY = "store.favorites.create_category",
  STORE_TREATMENT_PLAN_CLOSE_DRAWER = "store.treatment_plan.close_drawer",
  PATIENT_MOBILE_VIEW_IOS_APP = "patient.mobile.view_ios_app",
  PATIENT_MOBILE_VIEW_ANDROID_APP = "patient.mobile.view_android_app",
  STORE_SEARCH_CLOSE_FILTER = "store.search.close_filter",
  STORE_PLAN_OPTIMIZATION_VIEW_SUGGESTIONS = "store.plan_optimization.view_suggestions",
  STORE_PLAN_OPTIMIZATION_COMPARE_PRODUCTS = "store.plan_optimization.compare_products",
  STORE_PLAN_OPTIMIZATION_ACCEPT_SUGGESTION = "store.plan_optimization.accept_suggestion",
  STORE_TREATMENT_PLAN_APPLY_RESOURCE_FILTER = "store.treatment_plan.apply_resource_filter",
  STORE_TREATMENT_PLAN_OPEN_RESOURCE_DOCUMENT = "store.treatment_plan.open_resource_document",
  STORE_TREATMENT_PLAN_CHANGE_PLAN_TYPE = "store.treatment_plan.change_plan_type",
  STORE_TREATMENT_PLAN_VIEW_ACCESS = "store.treatment_plan.view_access",
  STORE_TREATMENT_PLAN_EDIT_DOCUMENT = "store.treatment_plan.edit_document",
  STORE_PAYMENTS_TO_SETUP_AUTOSHIP_DISCOUNT = "store.payments.to_setup_autoship_discount",
  STORE_IOC_ADD_ALL_TO_CART = "store.in_office_checkout.add_all_to_cart",
  STORE_IOC_ADD_TO_CART = "store.in_office_checkout.add_to_cart",
  STORE_IOC_START = "store.in_office_checkout.start",
  STORE_IOC_CONFIRM_AGE = "store.in_office_checkout.confirm_age",
  STORE_IOC_VIEW_AGE_VERIFICATION_MODULE = "store.in_office_checkout.view_age_verification_module",
  UNAUTH_MIGRATION_CONTINUE_MIGRATION = "unauth.migration.continue_migration",
  WELLEVATE_MIGRATION_FORGOT_WELLEVATE_PASSWORD = "wellevate.migration.forgot_wellevate_password",
  WELLEVATE_MIGRATION_TO_ACCOUNT_CREATION = "wellevate.migration.to_account_creation",
  STORE_DAILY_PACKS_APPLY_TEMPLATE_FILTER = "store.daily_packs.apply_daily_pack_template_filter",
  STORE_DAILY_PACKS_VIEW_DAILY_PACK_TEMPLATE = "store.daily_packs.view_daily_pack_template",
  STORE_DAILY_PACKS_CUSTOMIZE_DAILY_PACK = "store.daily_packs.customize_daily_pack",
  STORE_DAILY_PACKS_SAVE_AS_TEMPLATE = "store.daily_packs.save_as_template",
  STORE_TREATMENT_PLAN_VIEW_TEMPLATES_TAB = "store.treatment_plan.view_templates_tab",
  STORE_TREATMENT_PLAN_COPY_PLAN_LINK = "store.treatment_plan.copy_plan_link",
  STORE_TREATMENT_PLAN_UPDATE_PLAN = "store.treatment_plan.update_plan",
  STORE_TREATMENT_PLAN_APPLY_TEMPLATE = "store.treatment_plan.apply_template",
  STORE_TREATMENT_PLAN_ADD_ITEM_TO_DYNAMIC_DAILY_PACK = "store.treatment_plan.add_item_to_dynamic_daily_pack",
  STORE_TREATMENT_PLAN_APPLY_DAILY_PACK_FILTER = "store.treatment_plan.apply_daily_pack_filter",
  STORE_TREATMENT_PLAN_DELETE_PLAN = "store.treatment_plan.delete_plan",
  STORE_TREATMENT_PLAN_REVIEW_PLAN_HOVER_STATE = "store.treatment_plan.review_plan_hover_state",
  STORE_BREADCRUMBS_NAVIGATE = "store.breadcrumbs.navigate",
  STORE_SIDEBAR_TOGGLE_CLICK = "store.sidebar.toggle_click",
  STORE_SIDEBAR_AVATAR_MENU = "store.sidebar.avatar_menu",
  STORE_PATIENT_CURRENTLY_TAKING_VIEW = "store.patient.currently_taking_view",
  STORE_PATIENT_EXPORT_CURRENT_SUPPLEMENTS = "store.patient.export_current_supplements",
  STORE_PATIENT_REQUEST_SUPPLEMENT_CAPTURE = "store.patient.request_supplement_capture",
  PATIENT_MY_SCRIPT_ADD_PRODUCT = "patient.my_script.add_product",
  PATIENT_SUPPLEMENT_CAPTURE_START_INTAKE = "patient.supplement_capture.start_intake",
  STORE_THEME_DARK_MODE_TOGGLE = "store.theme.dark_mode_toggle",
  STORE_THEME_AUTOMATIC_DARK_MODE = "store.theme.automatic_dark_mode",
  PATIENT_THEME_DARK_MODE_TOGGLE = "patient.theme.dark_mode_toggle",
  STORE_PATIENT_VIEW = "store.patient.view_patient",
  PATIENT_AUTOSHIP_START_EDIT_DELIVERY_METHOD = "patient.autoship.start_edit_delivery_method",
  PATIENT_AUTOSHIP_UPDATE_DELIVERY_METHOD = "patient.autoship.update_delivery_method",
  STORE_WHOLESALE_POLICIES_VIEW_BRAND_AGREEMENT = "store.wholesale_policies.view_brand_agreement",
  EMERSON_SHOP_MENU_DROPDOWN = "emerson.shop_menu.dropdown",
  EMERSON_SHOP_MENU_CLICK_TO_NAVIGATE = "emerson.shop_menu.click_to_navigate",
  EMERSON_UNAUTH_SHOP_MENU_DROPDOWN = "emerson_unauth.shop_menu.dropdown",
  EMERSON_UNAUTH_SHOP_MENU_CLICK_TO_NAVIGATE = "emerson_unauth.shop_menu.click_to_navigate",
  PATIENT_MY_SCRIPT_VIEW_BULK_ADD = "patient.my_script.view_bulk_add",
  PATIENT_MY_SCRIPT_BULK_SAVE_PRODUCT = "patient.my_script.bulk_save_product",
  STORE_TREATMENT_PLAN_EDIT_ACCESS = "store.treatment_plan.access_edit",
  STORE_CHECKOUT_EDIT_ADDRESS = "store.checkout.edit_address",
  STORE_CHECKOUT_CONFIRM_ADDRESS = "store.checkout.confirm_address",
  STORE_CHECKOUT_ADDRESS_VALIDATION = "store.checkout.address_validation",
  EMERSON_CHECKOUT_EDIT_ADDRESS = "emerson.checkout.edit_address",
  EMERSON_CHECKOUT_CONFIRM_ADDRESS = "emerson.checkout.confirm_address",
  EMERSON_CHECKOUT_ADDRESS_VALIDATION = "emerson.checkout.address_validation",
  PATIENT_LABS_BOOK_APPOINTMENT = "patient.labs.book_appointment",
  PATIENT_CHECKOUT_REMOVE_LAB_TEST = "patient.checkout.remove_lab_test",
  PATIENT_LABS_VIEW_REQUISITION = "patient.labs.view_requisition",
  PATIENT_LABS_VIEW_RESULTS = "patient.labs.view_results",
  PATIENT_LABS_VIEW_TEST_DESCRIPTION = "patient.labs.view_test_description",
  PATIENT_LABS_JOURNEY_DOWNLOAD_TEST_RESULTS = "patient.labs_journey.download_test_results",
  PATIENT_LABS_JOURNEY_MANAGE_UPCOMING_TEST = "patient.labs_journey.manage_upcoming_test",
  PATIENT_CURRENT_SUPPLEMENTS_NAVIGATE = "patient.my_script.navigate_to_current_supplements",
  PATIENT_MEGA_MENU_DROPDOWN = "patient.mega_menu.dropdown",
  PATIENT_CHECKOUT_VIEW_AGE_VERIFICATION_MODULE = "patient.checkout.view_age_verification_module",
  PATIENT_CHECKOUT_CONFIRM_AGE = "patient.checkout.confirm_age",
  PATIENT_ACCOUNT_UPDATE_PERSONAL_PROFILE = "patient.account.update_personal_profile",
  EMERSON_QUICK_ORDER_OPEN_QUICK_ORDER = "emerson.quick_order.open_quick_order",
  EMERSON_ACCOUNT_MENU_SWITCH_PLATFORM = "emerson.account_menu.switch_platform",
  EMERSON_ACCOUNT_MENU_VIEW_MENU = "emerson.account_menu.view_menu",
  STORE_ACCOUNT_MENU_SWITCH_PLATFORM = "store.account_menu.switch_platform",
  STORE_PRODUCT_SWAP_VIEW = "store.product_swap.view",
  PATIENT_PRODUCT_SWAP_VIEW_ORIGINAL_PRODUCT = "patient.product_swap.view_original_product",
  STORE_PRODUCT_SWAP_VIEW_ORIGINAL_PRODUCT = "store.product_swap.view_original_product",
  PATIENT_PRODUCT_SWAP_VIEW_SUBSTITUTE_PRODUCTS = "patient.product_swap.view_substitute_products",
  STORE_PRODUCT_SWAP_SUBSTITUTE_DOSAGE_ACCORDION = "store.product_swap.substitute_dosage_accordion",
  STORE_PRODUCT_SWAP_VIEW_HELP_LINKS = "store.product_swap.view_help_links",
  STORE_PRODUCT_SWAP_START_ACTIVE_SWAP = "store.product_swap.start_active_swap",
  STORE_PRODUCT_SWAP_PREVIEW = "store.product_swap.preview",
  STORE_PRODUCT_SWAP_SELECT_PRODUCT = "store.product_swap.select_product",
  STORE_PRODUCT_SWAP_EDIT_SUBSTITUTE_PRODUCTS = "store.product_swap.edit_substitute_products",
  STORE_PRODUCT_SWAP_APPLY_FILTER = "store.product_swap.apply_filter",
  STORE_PRODUCT_SWAP_UPDATE_PLAN = "store.product_swap.update_plan",
  STORE_PRODUCT_SWAP_UPDATE_DOSAGE_INSTRUCTIONS = "store.product_swap.update_dosage_instructions",
  STORE_WHOLE_PERSON_PLAN_ADD_INGREDIENT = "store.whole_person_plan.add_ingredient",
  STORE_WHOLE_PERSON_PLAN_ADD_MEDICATIONS = "store.whole_person_plan.add_medications",
  STORE_WHOLE_PERSON_PLAN_GENERATE_INGREDIENTS = "store.whole_person_plan.generate_ingredients",
  STORE_WHOLE_PERSON_PLAN_SECTION_EXPANDED = "store.whole_person_plan.section_expanded",
  STORE_WHOLE_PERSON_PLAN_SEE_EVIDENCE_MODAL = "store.whole_person_plan.see_evidence_modal",
  STORE_WHOLE_PERSON_PLAN_DELETE_INGREDIENT = "store.whole_person_plan.delete_ingredient",
  STORE_WHOLE_PERSON_PLAN_START_OVER = "store.whole_person_plan.start_over",
  STORE_WHOLE_PERSON_PLAN_EVIDENCE_LINK_CLICKED = "store.whole_person_plan.evidence_link_clicked",
  STORE_WHOLE_PERSON_PLAN_EVIDENCE_RANKED_LEARN_MORE_CLICK = "store.whole_person_plan.evidence_ranked_learn_more_click",
  STORE_WHOLE_PERSON_PLAN_HEALTH_AREAS_ADDED = "store.whole_person_plan.health_areas_added",
  STORE_WHOLE_PERSON_PLAN_GET_PRODUCT_RECOMMENDATIONS = "store.whole_person_plan.get_product_recommendations",
  STORE_WHOLE_PERSON_PLAN_EXCLUDED_FROM_PLAN = "store.whole_person_plan.excluded_from_plan",
  STORE_WHOLE_PERSON_PLAN_CATEGORY_EXPANDED = "store.whole_person_plan.category_expanded",
}
